.menuFilters_ul {
  position: relative;
  margin: 24px 0 8px 0;
}

.menuFilters_li {
  display: inline-block;
}

.menuFilters {
  text-align: center;
}
