.footerMenuIcon {
  font-size: 30px;
  margin-bottom: 3px;
}

a {
  font-size: 12px;
}

@media screen and (max-width: 460px) {
  .footerMenuIcon {
    font-size: 20px;
    margin-bottom: 1px;
  }

  a {
    font-size: 9px;
  }
}
