.noTilesMessage {
  background-color: #f6f6f6;
  padding: 30px;
}

.noTilesImage {
  width: 120px;
}

.noTilesDescription {
  margin-top: 20px;
  font-size: 13px;
}
