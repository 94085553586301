.podiumContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  padding: 20px 0 20px 0;
  position: relative;
  overflow: hidden;
}
.podiumContainer span {
  color: white;
  font-weight: bold;
  font-size: 9px;
  margin: 4px;
}
.scale {
  z-index: 10;
}

.info {
  font-size: 20px;
  margin-top: 10px;
  margin-right: 6px;
  padding: 4px;
  text-align: right !important;
}
.info .coach-icon-info {
  color: #6d6d6d;
  font-size: 20px;
}
.info a {
  text-decoration: none;
}

.userBase {
  display: flex;
  position: relative;
  margin-top: 14px;
}

.rect {
  height: 30px;
  width: 90px;
  background-color: #40413f;
  display: inline-flex;
  justify-content: flex-end;
  align-items: center;
}
.triangle-left {
  width: 0;
  height: 0;
  border-top: 15px solid transparent;
  border-right: 10px solid #40413f;
  border-bottom: 15px solid transparent;
  display: inline-block;
}
.line {
  border-top: 1px dashed #40413f;
  position: absolute;
  margin-top: 15px;
  right: 100px;
  width: 200px;
}
.invisible {
  visibility: hidden;
}

.footNoteInsideChart {
  font-size: 12px;
  position: relative;
  bottom: 4px;
  right: 3px;
}

.footNoteOutsideChart {
  font-size: 12px;
  margin-bottom: 10px;
  position: relative;
}

.footNoteOutsideChartContainer {
  text-align: left;
}

/** uniquement pour la vue desktop **/
@media screen and (min-width: 769px) {
  .footNoteOutsideChart {
    font-size: 14px;
  }
  .userBase {
    margin-top: 20px;
  }
  .rect {
    height: 40px;
    width: 100px;
  }
  .triangle-left {
    width: 0;
    height: 0;
    border-top: 20px solid transparent;
    border-right: 15px solid #40413f;
    border-bottom: 20px solid transparent;
    display: inline-block;
  }
  .podiumContainer span {
    font-size: 12px;
    margin: 8px;
  }
}

@media screen and (min-width: 520px) and (max-width: 1023px) {
  .line {
    margin-top: 15px;
    width: 320px;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1244px) {
  .line {
    margin-top: 20px;
    width: 320px;
  }
}

@media screen and (min-width: 1245px) {
  .line {
    margin-top: 20px;
    width: 500px;
  }
}
