.infoCard {
  display: flex;
  flex-direction: column;
  margin: 15% auto 0;
  width: 35%;
  box-shadow:
    0 1px 3px 0 rgba(0, 0, 0, 0.2),
    0 1px 1px 0 rgba(0, 0, 0, 0.14),
    0 2px 1px -1px rgba(0, 0, 0, 0.12);
}

@media only screen and (max-width: 768px) {
  .infoCard {
    width: 100%;
  }
}

.titleCard {
  background-color: #97bf0d;
  font-weight: bold;
  font-size: 16px;
  color: white;
  text-transform: uppercase;
  padding: 8px;
}

.titleCardWarning {
  background-color: #ed741e;
  font-weight: bold;
  font-size: 16px;
  color: white;
  text-transform: uppercase;
  padding: 8px;
}

.detailCard {
  padding: 24px 12px 12px;
}

.detailCard img {
  width: 100px;
  margin-bottom: 14px;
}

.detailCard span {
  font-size: 14px;
  font-weight: bolder;
}
