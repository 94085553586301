.mainContent {
  display: flex;
  flex-flow: column nowrap;
  width: 93%;
  margin: 0 auto 120px;
  text-align: center;
  height: 100%;
}

.subContent {
  padding-top: 20px;
}

.chartContainer {
  margin: 8px 0 14px 0;
  background-color: #f6f6f6;
}

.chartTitle {
  font-size: 16px;
  font-weight: bold;
  text-transform: uppercase;
  background-color: #ededed;
  padding: 5px;
  text-align: center;
}

/* Grayed out part when user doesn't exist yet */
.coachWorking {
  position: absolute;
  width: 93%;
  height: 230%;
  background: grey;
  opacity: 0.9;
  z-index: 900;
}

.contentCoach {
  margin-top: 15%;
}

.coach {
  width: 180px;
}

.construct {
  width: 50px;
  margin-right: 10px;
}

.content {
  margin: 20px;
  color: white;
  font-size: 22px;
  font-weight: bolder;
}

.DashboardPanel {
  margin-bottom: 40px;
}

.linkButton {
  color: white;
  cursor: pointer;
  border: none;
  border-radius: 3px;
  width: 75px;
  padding: 6px 18px;
  margin: 0 auto 20px;
  text-transform: uppercase;
  text-decoration: none;
  background: linear-gradient(#97bf0d, #7f9f10);
}

.linkButton:hover {
  color: white;
  background: #7f9f10;
}

/************************************************/

/*** Only on Desktop View ***/
@media only screen and (min-width: 769px) {
  .DashboardPanel {
    float: left;
    width: 58%;
    margin-bottom: 0;
  }

  .DashboardTiles {
    width: 40%;
    float: right;
    margin-top: 84px;
    height: 640px;
    overflow-y: auto;
    padding-right: 5px;
  }

  .menuFilters_Button {
    font-size: 13px;
    margin-right: 10px;
  }

  .coachWorking {
    height: 75%;
  }
}

/*****************************/

.Toastify__toast--success {
  background-color: #97bf0d;
}

.Toastify__toast--warning {
  background-color: #f57f17;
}

/***********************************************
  TO DELETE WHEN TILES FEATURE WILL BE DEVELOP
************************************************/
.DashboardTilesTemp {
  position: absolute;
  background: grey;
  opacity: 0.85;
  z-index: 5;
  height: 640px;
}

.contentTilesTemp {
  margin-top: 25%;
}

.coach {
  width: 180px;
}

.construct {
  width: 50px;
  margin-right: 10px;
}

.content {
  margin: 20px;
  color: white;
  font-size: 22px;
  font-weight: bolder;
}

/***********************************************/
